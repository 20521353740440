import { combineReducers } from 'redux';
import {
    deleteScopes,
    getScopes,
    getUserCustomFieldRestrictions,
    getUserCustomFieldRestrictionsV2,
    getUsersMatchingScopes,
} from 'store/entities/scopes/actions';
import { IScope, IUserMatchItemResult } from 'store/entities/scopes/models';
import { deleteItemByIdReducer, isLoadingReducer, itemsByIdReducer, singleValueReducer } from 'store/reducerUtils';
import { extendReducer } from 'store/utils/reducers/extendReducer';

const scopesByIds = extendReducer(
    itemsByIdReducer<IScope, IScope>(getScopes),
    deleteItemByIdReducer<IScope>(deleteScopes),
);
const isLoading = isLoadingReducer(getScopes);
const userCustomFieldRestrictions = singleValueReducer(getUserCustomFieldRestrictions.successType, {});
const userCustomFieldRestrictionsByActions = singleValueReducer(
    getUserCustomFieldRestrictionsV2.successType,
    {},
);
const usersMatches = itemsByIdReducer<IUserMatchItemResult, IUserMatchItemResult>(getUsersMatchingScopes);

export const scopes = combineReducers({
    scopesByIds,
    isLoading,
    userCustomFieldRestrictions,
    userCustomFieldRestrictionsByActions,
    usersMatches,
});
