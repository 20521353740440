import { createSelector } from 'reselect';

import { IStore } from 'store/configureStore';

import {
    CustomFieldScopeRestrictions,
    IActivityScope,
    IScope,
    IUserMatchItemResult,
    ScopeAction,
    ScopeType,
} from 'store/entities/scopes/models';

export const selectScopesState = (store: IStore) => store.scopes;

/**
 * Scopes
 */
export const selectScopesByIds = (store: IStore): Record<string, IScope> => {
    return selectScopesState(store).scopesByIds;
};
export const selectScopesIsLoading = (store: IStore): boolean => {
    return selectScopesState(store).isLoading;
};
export const selectActivityScopes = createSelector(
    selectScopesByIds,
    (scopesById): IActivityScope[] => {
        return Object.values(scopesById).filter(scope => scope.type === ScopeType.Activity) as IActivityScope[];
    },
);
export const selectScopesByType = (type: ScopeType) => (store: IStore): IScope[] => {
    return Object.values(selectScopesByIds(store)).filter(scope => scope.type === type);
};

export const selectUserHasScopes = (store: IStore): boolean => {
    const scopesState = selectScopesState(store);
    const restrictionsDictionary = scopesState.userCustomFieldRestrictions;
    const restrictedFieldsIds = Object.keys(restrictionsDictionary);
    return restrictedFieldsIds.length > 0;
};

export const selectCustomFieldScopeRestrictions = (fieldId: string) => (store: IStore): Array<string> => {
    return selectScopesState(store).userCustomFieldRestrictions[fieldId] || [];
};

export const selectUserFieldValueRestrictionsByAction = (
    action?: ScopeAction,
) => (
    store: IStore,
): CustomFieldScopeRestrictions => {
    return selectScopesState(store).userCustomFieldRestrictionsByActions[action || ''] || {};
};
export const selectUserHasScopeRestrictionByType = (action?: ScopeAction) => (store: IStore): boolean => {
    return Object.keys(selectUserFieldValueRestrictionsByAction(action)(store)).length > 0;
};
export const selectCustomFieldScopeRestrictionsByAction = (
    action: ScopeAction | undefined,
    fieldId: string,
) => (store: IStore): Array<string> => {
    return selectUserFieldValueRestrictionsByAction(action)(store)[fieldId] || [];
};

export const selectScopesUserMatchesByUser = (store: IStore): Record<string, IUserMatchItemResult> => {
    return selectScopesState(store).usersMatches;
};
