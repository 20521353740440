import baseApi from 'shared/utils/baseApi';
import { withClientId } from 'store/utils/withClientId';
import {
    IBulkCreateSubassignmentResponseV2,
    IBulkCreateSubassignmentsRequest,
    IBulkCreateSubassignmentsResponse,
    IBulkDeleteSubassignmentsRequest,
    IBulkDeleteSubassignmentsResponse,
    IBulkEditSubassignmentPayloadV2,
    IBulkEditSubassignmentResponseV2,
    IBulkEditSubassignmentsRequest,
    IBulkEditSubassignmentsResponse,
    ISubassignmentSearchRequest,
    ISubassignmentSearchResponse,
} from 'modules/subassignmentManagement/store/models';

const prepareCsvUploadFormData = (file: File) => {
    const formData = new FormData();
    formData.append('csv', file);
    return formData;
};

export const subassignmentsApi = {
    async getGroupedSubassignments(
        request: ISubassignmentSearchRequest,
    ): Promise<ISubassignmentSearchResponse> {
        const { data } = await baseApi.post<ISubassignmentSearchRequest, ISubassignmentSearchResponse>(
            `/client-users/subassignments/grouped/search`,
            withClientId(request),
        );
        return data;
    },
    //Create v1
    async querySubassignmentPreCreationReport(
        request: IBulkCreateSubassignmentsRequest,
    ): Promise<IBulkCreateSubassignmentsResponse> {
        const { data } = await baseApi.post<IBulkCreateSubassignmentsRequest, IBulkCreateSubassignmentsResponse>(
            `/client-users/subassignments/bulk_create/query_report`,
            request,
        );
        return data;
    },
    async bulkCreateSubassignments(
        request: IBulkCreateSubassignmentsRequest,
    ): Promise<IBulkCreateSubassignmentsResponse> {
        const { data } = await baseApi.post<IBulkCreateSubassignmentsRequest, IBulkCreateSubassignmentsResponse>(
            `/client-users/subassignments/bulk_create`,
            request,
        );
        return data;
    },
    //Create v2
    async bulkCreateSubassignmentsV2(
        request: IBulkCreateSubassignmentsRequest,
    ): Promise<IBulkCreateSubassignmentResponseV2> {
        const { data } = await baseApi.post<IBulkCreateSubassignmentsRequest, IBulkCreateSubassignmentResponseV2>(
            `/client-users/subassignments/bulk_create/v2`,
            request,
        );
        return data;
    },
    async bulkCreateSubassignmentsV2QueryReportV2(
        request: IBulkCreateSubassignmentsRequest,
    ): Promise<IBulkCreateSubassignmentResponseV2> {
        const { data } = await baseApi.post<IBulkCreateSubassignmentsRequest, IBulkCreateSubassignmentResponseV2>(
            `/client-users/subassignments/bulk_create/v2/query_report`,
            request,
        );
        return data;
    },
    async bulkCreateSubassignmentsV2QueryReportV2Pdf(
        request: IBulkCreateSubassignmentsRequest,
    ): Promise<BlobPart> {
        const { data } = await baseApi.post<IBulkCreateSubassignmentsRequest, BlobPart>(
            `/client-users/subassignments/bulk_create/v2/query_report/pdf`,
            request,
            {
                responseType: 'blob',
            },
        );
        return data;
    },
    async bulkCreateSubassignmentsQueryFailedCsv(
        request: IBulkCreateSubassignmentsRequest,
    ): Promise<BlobPart> {
        const { data } = await baseApi.post<IBulkCreateSubassignmentsRequest, BlobPart>(
            `/client-users/subassignments/bulk_create/v2/download_csv`,
            request,
            {
                responseType: 'blob',
            },
        );
        return data;
    },
    //Bulk create CSV import
    async downloadCreationCsvSample(): Promise<BlobPart> {
        const { data } = await baseApi.post<{}, BlobPart>(
            `/client-users/subassignments/bulk_create/v2/csv_import/download_sample?client_id=${baseApi.clientId}`,
            {},
            {
                responseType: 'blob',
            },
        );
        return data;
    },
    async uploadCsvBulkCreateSubassignments(file: File): Promise<IBulkCreateSubassignmentResponseV2> {
        const { data } = await baseApi.post<FormData, IBulkCreateSubassignmentResponseV2>(
            `/client-users/subassignments/bulk_create/v2/csv_import?client_id=${baseApi.clientId}`,
            prepareCsvUploadFormData(file),
            {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            },
        );
        return data;
    },
    async uploadCsvBulkCreateSubassignmentsQueryReport(file: File): Promise<IBulkCreateSubassignmentResponseV2> {
        const { data } = await baseApi.post<FormData, IBulkCreateSubassignmentResponseV2>(
            `/client-users/subassignments/bulk_create/v2/csv_import/query_report?client_id=${baseApi.clientId}`,
            prepareCsvUploadFormData(file),
            {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            },
        );
        return data;
    },
    async uploadCsvBulkCreateSubassignmentsQueryReportPdf(file: File): Promise<BlobPart> {
        const { data } = await baseApi.post<FormData, BlobPart>(
            `/client-users/subassignments/bulk_create/v2/csv_import/query_report/pdf?client_id=${baseApi.clientId}`,
            prepareCsvUploadFormData(file),
            {
                headers: {
                    'content-type': 'multipart/form-data',
                },
                responseType: 'blob',
            },
        );
        return data;
    },
    async uploadCsvBulkCreateSubassignmentsQueryFailedCsv(file: File): Promise<BlobPart> {
        const { data } = await baseApi.post<FormData, BlobPart>(
            `/client-users/subassignments/bulk_create/v2/csv_import/download_csv?client_id=${baseApi.clientId}`,
            prepareCsvUploadFormData(file),
            {
                headers: {
                    'content-type': 'multipart/form-data',
                },
                responseType: 'blob',
            },
        );
        return data;
    },
    //Edit v1
    async querySubassignmentPreEditReport(
        request: IBulkEditSubassignmentsRequest,
    ): Promise<IBulkEditSubassignmentsResponse> {
        const { data } = await baseApi.post<IBulkEditSubassignmentsRequest, IBulkEditSubassignmentsResponse>(
            `/client-users/subassignments/bulk_update/query_report`,
            request,
        );
        return data;
    },
    async bulkEditSubassignments(
        request: IBulkEditSubassignmentsRequest,
    ): Promise<IBulkEditSubassignmentsResponse> {
        const { data } = await baseApi.post<IBulkEditSubassignmentsRequest, IBulkEditSubassignmentsResponse>(
            `/client-users/subassignments/bulk_update`,
            request,
        );
        return data;
    },
    //Edit v2
    async bulkEditSubassignmentsV2(
        request: IBulkEditSubassignmentPayloadV2,
    ): Promise<IBulkEditSubassignmentResponseV2> {
        const { data } = await baseApi.post<IBulkEditSubassignmentPayloadV2, IBulkEditSubassignmentResponseV2>(
            `/client-users/subassignments/bulk_update/v2`,
            request,
        );
        return data;
    },
    async bulkEditSubassignmentsQueryReportV2(
        request: IBulkEditSubassignmentPayloadV2,
    ): Promise<IBulkEditSubassignmentResponseV2> {
        const { data } = await baseApi.post<IBulkEditSubassignmentPayloadV2, IBulkEditSubassignmentResponseV2>(
            `/client-users/subassignments/bulk_update/v2/query_report`,
            request,
        );
        return data;
    },
    async bulkEditSubassignmentsQueryReportPdfV2(
        request: IBulkEditSubassignmentPayloadV2,
    ): Promise<BlobPart> {
        const { data } = await baseApi.post<IBulkEditSubassignmentPayloadV2, BlobPart>(
            `/client-users/subassignments/bulk_update/v2/query_report/pdf`,
            request,
            {
                responseType: 'blob',
            },
        );
        return data;
    },
    //Delete
    async querySubassignmentPreDeleteReport(
        request: IBulkDeleteSubassignmentsRequest,
    ): Promise<IBulkDeleteSubassignmentsResponse> {
        const { data } = await baseApi.post<IBulkDeleteSubassignmentsRequest, IBulkDeleteSubassignmentsResponse>(
            `/client-users/subassignments/bulk_delete/query_report`,
            request,
        );
        return data;
    },
    async bulkDeleteSubassignments(
        request: IBulkDeleteSubassignmentsRequest,
    ): Promise<IBulkDeleteSubassignmentsResponse> {
        const { data } = await baseApi.post<IBulkDeleteSubassignmentsRequest, IBulkDeleteSubassignmentsResponse>(
            `/client-users/subassignments/bulk_delete`,
            request,
        );
        return data;
    },
};
