import { IJobNumberBackend, IJobNumberUserType } from 'shared/models/JobNumber';
import { ICostCenter } from 'shared/models/CostCenters';
import { ILocation, ILocationBackend } from 'shared/models/Location';
import { IPosition } from 'shared/models/Position';
import baseApi from 'shared/utils/baseApi';
import { IClientInfo } from 'store/entities/clients/clientsModel';
import {
    IActivity,
    IActivityRequest,
    IArea,
    IAssignmentBackend,
    IAssignmentsRequest,
    IAssignmentWithLinked,
    IAssignmentWithLinkedRequest,
    IBackgroundCheckTemplate,
    IConfigurationByAssignments,
    IDealRequest, IDealResponse,
    IJobNumberPostRequest,
    IJobNumberRequest,
    ILocationRequest,
    IPhysicalDemandsTemplate,
    IProject,
    IProjectsRequest,
    IProjectWithAssignmentBackend,
    ISubassignment,
    ISubassignmentRequest,
    ITask,
    IWorkingConditionsTemplate,
} from 'store/entities/configuration/configurationModel';
import { IControllingOrg } from 'shared/models/ControllingOrg';
import { ISubmittingOrg } from 'shared/models/SubmittingOrg';
import { ISubmittingOrgLocation } from 'shared/models/SubmittingOrgLocation';
import { IDealType } from 'shared/models/DealType';

import { withClientId } from '../../utils/withClientId';
import { IRequestWithClientId } from 'shared/models/Request';
import { ISubmittingOrgGenworthBranch } from 'shared/models/SubmittingOrgGenworthBranch';
import { IDealSegment } from 'shared/models/DealSegment';
import { IDealTypeArBucket } from '../../../shared/models/DealTypeArBucket';

export const clientConfigurationsService = 'client-configurations';
const configurationPath = `/${clientConfigurationsService}`;
const usersPath = '/client-users';

export const gwClientExtensionsService = 'gw-clientextensions';
export const controllingOrgEntity = 'controlling_orgs';

export const submittingOrgEntity = 'submitting_orgs';
export const costCenterEntity = 'cost_centers';
export const jobNumberEntity = 'job_numbers';
export const dealEntity = 'deals';

export const activityEntity = 'activities';

export const configurationApi = {
    async getProjectsWithAssignments(params: IAssignmentsRequest = {}): Promise<Array<IProjectWithAssignmentBackend>> {
        const { data } = await baseApi.get<{ projects_with_assignment: Array<IProjectWithAssignmentBackend> }>(
            `${configurationPath}/coordinator/client-configurations/projects-with-assignment`, withClientId(params),
        );
        return data.projects_with_assignment;
    },
    async getAreas(): Promise<Array<IArea>> {
        const { data } = await baseApi.get<{ areas: Array<IArea> }>(`${usersPath}/areas`);
        return data.areas;
    },
    async getActivities(params: IActivityRequest = {}): Promise<Array<IActivity>> {
        const { data } = await baseApi.get<{ activities: Array<IActivity> }>(
            `${configurationPath}/${activityEntity}`, withClientId(params),
        );
        return data.activities;
    },
    async getTasks(params: IConfigurationByAssignments = {}): Promise<Array<ITask>> {
        const { data } = await baseApi.get<{ tasks: Array<ITask> }>(
            `${configurationPath}/tasks`, withClientId(params),
        );
        return data.tasks;
    },
    async getProjects(params: IProjectsRequest = {}): Promise<Array<IProject>> {
        const { data } = await baseApi.get<{ projects: Array<IProject> }>(
            `${configurationPath}/projects`, withClientId(params),
        );
        return data.projects;
    },
    async getAssignments(request?: IAssignmentsRequest): Promise<Array<IAssignmentBackend>> {
        const ids = Array.isArray(request?.ids) ? request?.ids.join(',') : request?.ids;
        const requestObj = { ...(request || {}), ids };
        const { data } = await baseApi.post<IAssignmentsRequest, { assignments: Array<IAssignmentBackend> }>(
            `${usersPath}/get_assignments`, withClientId(requestObj),
        );
        return data.assignments;
    },
    async getLocations(request: ILocationRequest = {}): Promise<ILocation[]> {
        const { data } = await baseApi.get<{ locations: Array<any> }>(
            `${usersPath}/locations`, withClientId(request),
        );
        return data.locations.map((location: ILocationBackend) => ({
            ...location,
            positions: location.positions.map(position => position.id),
        }));
    },
    async getControllingOrgs({ client_id }: IRequestWithClientId): Promise<IControllingOrg[]> {
        const url = `/${gwClientExtensionsService}/${controllingOrgEntity}?client_id=${client_id || baseApi.clientId}`;
        const { data } = await baseApi.get<{ controlling_orgs: Array<IControllingOrg> }>(url);
        return data.controlling_orgs;
    },
    async getSubmittingOrgs({ client_id }: IRequestWithClientId): Promise<ISubmittingOrg[]> {
        const url = `/${gwClientExtensionsService}/${submittingOrgEntity}?client_id=${client_id || baseApi.clientId}`;
        const { data } = await baseApi.get<{ submitting_orgs: Array<ISubmittingOrg> }>(url);
        return data.submitting_orgs;
    },
    async getSubmittingOrgLocations({ client_id }: IRequestWithClientId): Promise<ISubmittingOrgLocation[]> {
        const url = `/${gwClientExtensionsService}/clients/${client_id || baseApi.clientId}/submitting_org_locations`;
        const { data } = await baseApi.get<{ locations: Array<ISubmittingOrgLocation> }>(url);
        return data.locations;
    },
    async getSubmittingOrgGenworthBranches(): Promise<ISubmittingOrgGenworthBranch[]> {
        const url = `/${gwClientExtensionsService}/branches`;
        const { data } = await baseApi.get<{ branches: Array<ISubmittingOrgGenworthBranch> }>(url);
        return data.branches;
    },
    async getPositions(): Promise<IPosition[]> {
        const { data } = await baseApi.get<{ positions: Array<any> }>(
            `${usersPath}/positions`, withClientId({}),
        );
        return data.positions;
    },
    async getClientsInformation(): Promise<Array<IClientInfo>> {
        const { data } = await baseApi.get<{ clients: Array<IClientInfo> }>(
            `${usersPath}/clients`,
        );
        return data.clients;
    },
    async getWorkingConditions(): Promise<IWorkingConditionsTemplate[]> {
        const { data } = await baseApi.get<{ working_conditions: Array<IWorkingConditionsTemplate> }>(
            `${usersPath}/working_conditions`, withClientId({}),
        );
        return data.working_conditions;
    },
    async getPhysicalDemands(): Promise<IPhysicalDemandsTemplate[]> {
        const { data } = await baseApi.get<{ physical_demands: Array<IPhysicalDemandsTemplate> }>(
            `${usersPath}/physical_demands`, withClientId({}),
        );
        return data.physical_demands;
    },
    async getBackgroundCheckTemplates(): Promise<IBackgroundCheckTemplate[]> {
        const { data } = await baseApi.get<{ background_checks: Array<IBackgroundCheckTemplate> }>(
            `${usersPath}/background_checks`, withClientId({}),
        );
        return data.background_checks;
    },
    async getAssignmentsWithLinked(request: IAssignmentWithLinkedRequest): Promise<IAssignmentWithLinked[]> {
        const { data } = await baseApi.get<{ assignments: Array<IAssignmentWithLinked> }>(
            `${usersPath}/assignments/full`, withClientId(request),
        );
        return data.assignments;
    },
    async getCostCenters(): Promise<ICostCenter[]> {
        const clientId = baseApi.clientId;
        const { data } = await baseApi.get<{ cost_centers: ICostCenter[] }>(
            `${gwClientExtensionsService}/clients/${clientId}/${costCenterEntity}`,
        );
        return data.cost_centers;
    },
    async getJobNumbers(request: IJobNumberRequest = {}): Promise<IJobNumberBackend[]> {
        const postRequest = {
            ...request,
            user_id: request?.user_id ? request?.user_id.join(',') : undefined,
            ids: request?.ids ? request?.ids.join(',') : undefined,
        };
        const { data } = await baseApi.post<IJobNumberPostRequest, { job_numbers: IJobNumberBackend[] }>(
            `/${gwClientExtensionsService}/get_job_numbers`,
            withClientId(postRequest),
        );
        return data.job_numbers;
    },
    async getUserTypes(): Promise<IJobNumberUserType[]> {
        const { data } = await baseApi.get<{ user_types: IJobNumberUserType[] }>(
            `/${gwClientExtensionsService}/user_types`,
            withClientId({}),
        );
        return data.user_types;
    },
    async getDeals(request: IDealRequest): Promise<IDealResponse> {
        const { data } = await baseApi.get<IDealResponse>(
            `/${gwClientExtensionsService}/${dealEntity}`,
            withClientId(request),
        );
        return data;
    },
    async getDealTypes(): Promise<IDealType[]> {
        const { data } = await baseApi.get<{ deal_types: IDealType[] }>(
            `/${gwClientExtensionsService}/${dealEntity}/types`,
            withClientId({}),
        );
        return data.deal_types;
    },
    async getDealSegments(): Promise<IDealSegment[]> {
        const { data } = await baseApi.get<{ deal_segments: IDealSegment[] }>(
            `/${gwClientExtensionsService}/deal_segments`,
        );
        return data.deal_segments;
    },
    async getDealTypeArBuckets(): Promise<string[]> {
        const { data } = await baseApi.get<{ ar_buckets: string[] }>(
            `/${gwClientExtensionsService}/deals/ar_buckets`,
        );
        return data.ar_buckets;
    },
    async getDealTypePayCodeArBuckets(): Promise<IDealTypeArBucket[]> {
        const { data } = await baseApi.get<{ pay_code_deal_types: IDealTypeArBucket[] }>(
            `/${gwClientExtensionsService}/deals/ar_buckets/pay_code_deal_types`,
        );
        return data.pay_code_deal_types;
    },
    async getSubassignment(request: ISubassignmentRequest): Promise<ISubassignment[]> {
        const { data } = await baseApi.post<ISubassignmentRequest, { subassignments: ISubassignment[] }>(
            `${usersPath}/get_subassignments`,
            request,
        );
        return data.subassignments;
    },
};
